import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import product1 from "../../assets/images/products/product1.jpg";
import product2 from "../../assets/images/products/product2.jpg";
import product3 from "../../assets/images/products/product3.jpg";
import product4 from "../../assets/images/products/product4.jpg";
import product5 from "../../assets/images/products/product5.jpg";
import product6 from "../../assets/images/products/product6.jpg";

const TabOne = [
  {
    image: product1,
    bigImage: product1,
  },
  {
    image: product2,
    bigImage: product2,
  },
  {
    image: product3,
    bigImage: product3,
  },
  {
    image: product4,
    bigImage: product4,
  },
  {
    image: product5,
    bigImage: product5,
  },
  {
    image: product6,
    bigImage: product6,
  },
];

class TabStyleThree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab1: 0,
      tab2: 0,
      tab3: 0,
      tab4: 0,
      isOpen: false,
    };
  }
  render() {
    const { column } = this.props;
    const { tab1, tab2, tab3, tab4, isOpen } = this.state;
    return (
      <div>
        <div>
          <div className="row row--35">
            {TabOne.map((value, index) => (
              <div className={`${column}`} key={index}>
                {isOpen && (
                  <Lightbox
                    mainSrc={TabOne[tab1].bigImage}
                    nextSrc={TabOne[(tab1 + 1) % TabOne.length]}
                    prevSrc={TabOne[(tab1 + TabOne.length - 1) % TabOne.length]}
                    onCloseRequest={() => this.setState({ isOpen: false })}
                    onMovePrevRequest={() =>
                      this.setState({
                        tab1: (tab1 + TabOne.length - 1) % TabOne.length,
                      })
                    }
                    onMoveNextRequest={() =>
                      this.setState({
                        tab1: (tab1 + 1) % TabOne.length,
                      })
                    }
                    imageLoadErrorMessage="Image Loading ..."
                    enableZoom={false}
                  />
                )}
                <div className="item-portfolio-static">
                  <div
                    onClick={() => this.setState({ isOpen: true, tab1: index })}
                  >
                    <div className="portfolio-static">
                      <div className="thumbnail-inner">
                        <div className="thumbnail">
                          <a href="#portfolio-details">
                            <img src={value.image} alt="Portfolio Images" />
                          </a>
                        </div>
                      </div>
                      <div className="content">
                        <div className="inner">
                          <p>{value.category}</p>
                          <h4>
                            <a href="#portfolio-details">{value.title}</a>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default TabStyleThree;
